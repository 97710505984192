<template>
    <div>
        <section class="pt-0 cover-background ipad-top-space-margin sm-pb-0"
            style="background-image:url('https://pub-a99445c9dcce4e8f89bb4c3007d44c73.r2.dev/front.png');">
            <div class="shape-image-animation bottom-0 p-0 w-100 d-none d-md-block">
                <svg xmlns="http://www.w3.org/2000/svg" widht="3000" height="400" viewBox="0 180 2500 200"
                    fill="#f0f2f8">
                    <path class="st1" d="M 0 250 C 1200 400 1200 50 3000 250 L 3000 550 L 0 550 L 0 250">
                        <animate attributeName="d" dur="5s" values="M 0 250 C 1200 400 1200 50 3000 250 L 3000 550 L 0 550 L 0 250;
                            M 0 250 C 400 50 400 400 3000 250 L 3000 550 L 0 550 L 0 250;
                            M 0 250 C 1200 400 1200 50 3000 250 L 3000 550 L 0 550 L 0 250" repeatCount="indefinite" />
                    </path>
                </svg>
            </div>
            <div class="container">
                <div class="row align-items-center justify-content-center h-400px sm-h-300px">
                    <div
                        class="col-12 col-xl-6 col-lg-8 col-md-10 position-relative text-center page-title-extra-large d-flex flex-wrap flex-column align-items-center justify-content-center">

                        <h1 class="mb-20px text-white fw-600 ls-minus-1px">{{ text }}</h1>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const link = ref(route.path);

const text = computed(() => {
    if (link.value === '/services') {
        return 'Services';
    } else if (link.value === '/why-us') {
        return 'Why Chose Us';
    } else if (link.value === '/testimonials') {
        return 'Testimonials';
    } else if (link.value === '/apps') {
        return 'Apps';
    } else if (link.value === '/join-us') {
        return 'Join Us';
    } else if (link.value === '/contact') {
        return 'Contact';
    } else {
        return '';
    }

});
</script>