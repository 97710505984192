<template>
    <div>
        <section class="bg-very-light-gray pt-2 bg-opacity">
            <div class="container">
                <div class="row justify-content-center mb-3">
                    <div class="col-xl-8 col-lg-9 col-md-10 text-center">
                        <h3 class="fw-700 ls-minus-1px header-text" style="color: #ffff;">
                            {{ titleSection }}</h3>
                        <span class="d-block fs-17 text-white fw-500 mb-5px header-text">
                            {{ secondSectionContent }}
                        </span>
                    </div>
                </div>
                <div class="row row-cols-1 row-cols-lg-3 row-cols-sm-2 justify-content-center">
                    <div class="col icon-with-text-style-04 transition-inner-all md-mb-30px pb-3"
                        v-for="(section, index) in sectionsData" 
                        :key="index">
                        <div class="feature-box-wrapper"
                            @mouseenter="showContent(index)"
                            @mouseleave="hideContent(index)"
                            :class="{ 'hovered': section.showContent }">
                            <div class="feature-box transition dark-hover bg-white border-radius-6px pt-17 pb-17 ps-15 pe-15 lg-ps-8 lg-pe-8 last-paragraph-no-margin box-shadow-quadruple-large overflow-hidden cursor-pointer">
                                <div class="feature-box-icon">
                                    <img :src="section.image" alt="" class="icon-image">
                                </div>
                                <div class="feature-box-content">
                                    <span class="d-inline-block text-dark-gray fw-600 fs-17 mb-5px">{{ section.title }}</span>
                                    <p class="content-transition" :class="{ 'show-content': section.showContent }">
                                        {{ section.content }}
                                    </p>
                                </div>
                                <div class="feature-box-overlay"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

const titleSection = ref('Loading...');
const secondSectionContent = ref('Loading...');
const secondSectionImage = ref('');
const sectionsData = ref([]);

const fetchData = async () => {
    const page = 'HomePage';
    const sectionID = '665849501b769ba8c36b9591';

    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}/sections/${sectionID}`);
        const section = response.data;
        titleSection.value = section.title;

        if (section && section.images && section.images.length > 0) {
            secondSectionImage.value = section.images[0].url;
        }
        secondSectionContent.value = section.content;
    } catch (error) {
        console.error(error);
        secondSectionImage.value = 'default_image_url';
        secondSectionContent.value = 'Error loading content';
    }
};

const fetchMultipleSectionsData = async (sectionIDs) => {
    const page = 'HomePage';
    try {
        const promises = sectionIDs.map(sectionID =>
            axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}/sections/${sectionID}`)
        );
        const responses = await Promise.all(promises);
        sectionsData.value = responses.map(response => {
            const section = response.data;
            if (section && section.images && section.images.length > 0) {
                return {
                    title: section.title,
                    image: section.images[0].url,
                    content: section.content,
                    showContent: false
                };
            } else {
                return {
                    title: 'No title available',
                    image: '',
                    content: 'No content available',
                    showContent: false
                };
            }
        });
    } catch (error) {
        console.error('Error fetching data:', error);
        sectionsData.value = [{ title: 'Error', content: 'Error loading content', showContent: false }];
    }
};

const showContent = (index) => {
    sectionsData.value[index].showContent = true;
};

const hideContent = (index) => {
    sectionsData.value[index].showContent = false;
};

onMounted(() => {
    fetchData();
    fetchMultipleSectionsData([
        '665849501b769ba8c36b9593',
        '665849501b769ba8c36b9595',
        '665849501b769ba8c36b9597',
        '665849501b769ba8c36b9599',
        '665849501b769ba8c36b959b'
    ]);
});
</script>

<style scoped>
.bg-opacity {
    position: relative;
    background-image: url('https://pub-a99445c9dcce4e8f89bb4c3007d44c73.r2.dev/gradient.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.bg-opacity::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(209, 21, 21, 0.5);
    z-index: 1;
}

.header-text {
    position: relative;
    z-index: 2;
}

.feature-box-wrapper {
    position: relative;
    z-index: 2;
    transition: transform 0.3s ease-in-out;
}

.feature-box-wrapper.hovered {
    transform: translateY(-5px);
}

.feature-box {
    position: relative;
    transition: all 0.3s ease-in-out;
    background: white;
    height: 100%;
}

.feature-box-icon {
    margin-bottom: 15px;
    position: relative;
    z-index: 1;
    transition: transform 0.3s ease-in-out;
}

.icon-image {
    height: 40px;
    width: auto;
    transition: transform 0.3s ease-in-out;
}

.feature-box-content {
    position: relative;
    z-index: 1;
}

.content-transition {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    margin: 0;
    color: #666;
}

.content-transition.show-content {
    max-height: 200px;
    opacity: 1;
    margin: 10px 0;
}

.feature-box-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, #ff4747, #ff6b6b);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 0;
}

.feature-box-wrapper.hovered .feature-box-overlay {
    opacity: 0.05;
}

.feature-box-wrapper.hovered .icon-image {
    transform: scale(1.1);
}

/* Additional utility classes */
.cursor-pointer {
    cursor: pointer;
}

.box-shadow-quadruple-large {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
    transition: box-shadow 0.3s ease-in-out;
}

.feature-box-wrapper.hovered .box-shadow-quadruple-large {
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}

/* Responsive padding utilities */
.pt-17 { padding-top: 17px; }
.pb-17 { padding-bottom: 17px; }
.ps-15 { padding-left: 15px; }
.pe-15 { padding-right: 15px; }

@media (max-width: 992px) {
    .lg-ps-8 { padding-left: 8px; }
    .lg-pe-8 { padding-right: 8px; }
    .md-mb-30px { margin-bottom: 30px; }
}

.btn {
    cursor: pointer;
    position: relative;
    z-index: 10;
}

.btn:hover {
    opacity: 0.9;
}

.btn:active {
    transform: scale(0.98);
}
</style>