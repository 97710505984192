<template>
    <div class="contact-services">

        <!-- Contact Info Cards -->
        <section class="info-section">
            <div class="container">
                <div class="info-grid">
                    <!-- Location Card -->
                    <div class="info-card">
                        <div class="icon-wrapper">
                            <i class="fas fa-map-marker-alt"></i>
                        </div>
                        <div class="content">
                            <h3>Visit Us</h3>
                            <p>{{ sections[2]?.content || 'Loading...' }}</p>
                        </div>
                    </div>

                    <!-- Phone Card -->
                    <div class="info-card">
                        <div class="icon-wrapper">
                            <i class="fas fa-phone-alt"></i>
                        </div>
                        <div class="content">
                            <h3>Call Us</h3>
                            <div v-if="sections[3]?.content">
                                <div>
                                    <strong>Main Office:</strong><br>
                                    {{ sections[3].content.split(',')[0].trim() }}
                                </div>
                                <div class="mt-2">
                                    <strong>Alternate:</strong><br>
                                    {{ sections[3].content.split(',')[1].trim() }}
                                </div>
                            </div>
                            <div v-else>Loading...</div>
                        </div>
                    </div>

                    <!-- Email Card -->
                    <div class="info-card">
                        <div class="icon-wrapper">
                            <i class="fas fa-envelope"></i>
                        </div>
                        <div class="content">
                            <h3>Email Us</h3>
                            <a href="mailto:contact@example.com">contact@example.com</a>
                        </div>
                    </div>

                    <!-- WhatsApp Card -->
                    <div class="info-card">
                        <div class="icon-wrapper">
                            <i class="fab fa-whatsapp"></i>
                        </div>
                        <div class="content">
                            <h3>WhatsApp</h3>
                            <a href="https://wa.me/1234567890" target="_blank">+1 (234) 567-890</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Map Section -->
        <section class="map-section">
            <iframe :src="sections[1] ? getIframeSrc(sections[1].content) : ''" loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
        </section>

        <!-- Contact Form Section -->
        <section class="contact-form-section">
            <div class="container">
                <div class="form-content">
                    <div class="form-header">
                        <h2>Send us a Message</h2>
                        <p>Fill out the form below and we'll get back to you shortly.</p>
                    </div>

                    <form @submit.prevent="submitForm" class="contact-form">
                        <div class="form-grid">
                            <div class="form-group">
                                <input v-model="formData.name" type="text" placeholder="Your Name*" required />
                            </div>
                            <div class="form-group">
                                <input v-model="formData.email" type="email" placeholder="Your Email*" required />
                            </div>
                            <div class="form-group full-width">
                                <input v-model="formData.subject" type="text" placeholder="Subject" />
                            </div>
                            <div class="form-group full-width">
                                <textarea v-model="formData.message" placeholder="Your Message" rows="4"></textarea>
                            </div>
                        </div>
                        <button type="submit" class="submit-button">
                            <span>Send Message</span>
                            <i class="fas fa-paper-plane"></i>
                        </button>
                    </form>
                </div>
            </div>
        </section>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import '@fortawesome/fontawesome-free/css/all.css';

const toast = useToast();
const sections = ref([]);
const formData = ref({
    name: '',
    email: '',
    subject: '',
    message: ''
});

const getIframeSrc = (content) => {
    const match = content.match(/src="([^"]*)"/i);
    return match?.[1] || '';
};

const fetchData = async () => {
    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/ContactUsPage`);
        sections.value = response.data.sections;
    } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Failed to load contact information');
    }
};

const submitForm = async () => {
    try {
        await axios.post(`${process.env.VUE_APP_API_BASE_URL}/contact/add`, formData.value);
        formData.value = { name: '', email: '', subject: '', message: '' };
        toast.success('Message sent successfully!');
    } catch (error) {
        console.error('Error submitting form:', error);
        toast.error('Failed to send message. Please try again.');
    }
};

onMounted(fetchData);
</script>

<style scoped>
.contact-services {
    --primary-color: #ff3366;
    --primary-dark: #e61e4d;
    --text-color: #2d3436;
    --background-color: #ffffff;
    --card-background: #ffffff;
    --section-spacing: 5rem;
    color: var(--text-color);
}

/* Hero Section */
.hero-section {
    background: linear-gradient(135deg, var(--primary-color), var(--primary-dark));
    color: white;
    padding: 6rem 0;
    text-align: center;
}

.hero-section h1 {
    font-size: 3.5rem;
    margin-bottom: 1rem;
    font-weight: 700;
}

.hero-section p {
    font-size: 1.2rem;
    opacity: 0.9;
}

/* Container */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1.5rem;
}

/* Info Section */
.info-section {
    padding: var(--section-spacing) 0;
    background-color: #f8f9fa;
}

.info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
}

.info-card {
    background: var(--card-background);
    padding: 2rem;
    border-radius: 16px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    text-align: center;
}

.info-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
}

.icon-wrapper {
    color: var(--primary-color);
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
}

.info-card h3 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: var(--text-color);
}

.info-card a {
    color: var(--primary-color);
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;
}

.info-card a:hover {
    color: var(--primary-dark);
}

/* Map Section */
.map-section {
    height: 500px;
    position: relative;
}

.map-section iframe {
    width: 100%;
    height: 100%;
    border: none;
    filter: grayscale(0.2);
}

/* Contact Form Section */
.contact-form-section {
    padding: var(--section-spacing) 0;
    background-color: var(--background-color);
}

.form-content {
    max-width: 800px;
    margin: 0 auto;
    background: white;
    padding: 3rem;
    border-radius: 20px;
    box-shadow: 0 15px 50px rgba(0, 0, 0, 0.05);
}

.form-header {
    text-align: center;
    margin-bottom: 3rem;
}

.form-header h2 {
    font-size: 2.5rem;
    color: var(--text-color);
    margin-bottom: 1rem;
}

.form-header p {
    color: #666;
}

.form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
}

.form-group.full-width {
    grid-column: 1 / -1;
}

input,
textarea {
    width: 100%;
    padding: 1rem 1.5rem;
    border: 2px solid #eee;
    border-radius: 12px;
    font-size: 1rem;
    transition: all 0.3s ease;
    background: #f8f9fa;
}

input:focus,
textarea:focus {
    border-color: var(--primary-color);
    background: white;
    outline: none;
}

.submit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin: 2rem auto 0;
    padding: 1rem 2.5rem;
    background: var(--primary-color);
    color: white;
    border: none;
    border-radius: 12px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.submit-button:hover {
    background: var(--primary-dark);
    transform: translateY(-2px);
}

.submit-button i {
    font-size: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
    .hero-section h1 {
        font-size: 2.5rem;
    }

    .form-grid {
        grid-template-columns: 1fr;
    }

    .form-content {
        padding: 2rem;
    }

    .info-section {
        padding: 3rem 0;
    }

    .map-section {
        height: 400px;
    }
}

@media (max-width: 480px) {
    .hero-section h1 {
        font-size: 2rem;
    }

    .form-header h2 {
        font-size: 2rem;
    }
}

.mt-2 {
    margin-top: 0.75rem;
}
</style>