<template>
    <div>
        <section class="bg-gradient-very-light-gray position-relative">
            <div class="container">
                <div class="row">
                    <div class="col tab-style-01">
                        <ul class="nav nav-tabs justify-content-center border-0 text-center fs-18 alt-font fw-600 mb-3">
                            <li class="nav-item"><a class="nav-link active" data-bs-toggle="tab" href="#tab_sec1">{{
                                fatchDataOfInternTitle }}</a></li>
                            <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" href="#tab_sec2">{{
                                titleSectionPre }}</a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <!-- start tab content -->
                            <div class="tab-pane fade in active show" id="tab_sec1">
                                <div class="row justify-content-center align-items-center">
                                    <div class="col-md-6 animation-float sm-mb-50px">
                                        <img :src="fatchDataOfInternImage" alt="">

                                    </div>
                                    <div class="col-lg-5 offset-lg-1 col-md-6 text-center text-md-start">

                                        <p class="w-80 xl-w-85 lg-w-90 md-w-100 mb-30px">{{ fatchDataOfInternContent }}
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <!-- end tab content -->
                            <!-- start tab content -->
                            <div class="tab-pane fade in" id="tab_sec2">
                                <div class="row justify-content-center align-items-center">
                                    <div class="col-md-6 animation-float sm-mb-50px">
                                        <img :src="secondSectionImagePre" alt="">
                                    </div>
                                    <div class="col-lg-5 offset-lg-1 col-md-6 text-center text-md-start">


                                        <p class="w-80 xl-w-85 lg-w-90 md-w-100 mb-30px">{{ secondSectionContentPre }}
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <hr>
        <section id="down-section" class="overflow-hidden">
            <div class="container">
                <div class="row g-0 justify-content-center">
                    <div class="col-lg-3 col-md-6">
                        <div class="h-100 sm-h-500px xs-h-400px cover-background"
                            style="background-image: url(https://images.pexels.com/photos/3182834/pexels-photo-3182834.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)">
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="bg-red p-18 lg-p-15 h-100">
                            <span class="text-white text-decoration-line-bottom mb-10px d-inline-block">{{
                                titleSectionExp }}</span>
                            <p class="text-white ">{{ secondSectionContentExp }}</p>
                            <span class="text-white text-decoration-line-bottom mb-10px d-inline-block">{{
                                titleSectionGet }}</span>
                            <p>
                            <ul class="text-white ">
                                <li v-for="(item, index) in secondSectionContentGet.split('•').slice(1)" :key="index">
                                    {{ item.replace('•', '').trim() }}
                                </li>
                            </ul>
                            </p>

                        </div>
                    </div>
                    <div class="col-lg-4 offset-lg-1">
                        <div class="pt-5 md-pt-45px contact-form-style-01">
                            <span class="text-dark text-decoration-line-bottom mb-10px d-inline-block">{{
                                fetchDataFormContent }}</span>
                            <!-- start contact form -->
                            <form @submit.prevent="submitForm" id="contactform">
                                <div class="position-relative form-group mb-20px">
                                    <span class="form-icon"><i class="bi bi-emoji-smile"></i></span>
                                    <input type="text" name="name" class="form-control required"
                                        placeholder="Your name*" v-model="formData.name" id="name" required>
                                </div>
                                <div class="position-relative form-group mb-20px">
                                    <span class="form-icon"><i class="bi bi-envelope"></i></span>
                                    <input type="email" name="email" class="form-control required"
                                        placeholder="Your email address*" v-model="formData.email" required>
                                </div>
                                <div class="position-relative form-group mb-20px">
                                    <span class="form-icon"><i class="bi bi-envelope"></i></span>
                                    <input type="text" name="text" class="form-control required" 
                                        placeholder="Subject" v-model="formData.subject" required>
                                </div>
                                <div class="position-relative form-group mb-20px">
                                    <span class="form-icon"><i class="bi bi-upload"></i></span>
                                    <input type="file" name="file" class="form-control"
                                        v-on:change="handleFileUpload($event)">
                                </div>

                                <div class="position-relative form-group form-textarea">
                                    <span class="form-icon"><i class="bi bi-chat-square-dots"></i></span>
                                    <textarea placeholder="Subject" name="comment" class="form-control" rows="3"
                                        v-model="formData.message"></textarea>
                                    <input type="hidden" name="redirect" value="">
                                    <button
                                        class="btn btn-small btn-round-edge btn-dark-gray btn-box-shadow mt-20px m-auto submit"
                                        type="button"
                                        @click="submitForm">
                                        Send message
                                    </button>
                                    <div class="form-results mt-20px d-none"></div>
                                </div>
                            </form>
                            <!-- end contact form -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>


<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import Swal from 'sweetalert2';

const formData = ref({
    name: '',
    email: '',
    subject: '',
    message: ''
});
// const sections = ref([]);


const titleSectionExp = ref('Loading...');
const secondSectionContentExp = ref('Loading...');
const secondSectionImageExp = ref('');

const titleSectionGet = ref('Loading...');
const secondSectionContentGet = ref('Loading...');
const secondSectionImageGet = ref('');

const fetchDataFormTitile = ref('Loading...');
const fetchDataFormContent = ref('Loading...');
const fetchDataFormImage = ref('');

const fatchDataOfInternTitle = ref('Loading...');
const fatchDataOfInternContent = ref('Loading...');
const fatchDataOfInternImage = ref('');

const titleSectionPre = ref('Loading...');
const secondSectionContentPre = ref('Loading...');
const secondSectionImagePre = ref('');

// Add file handling
const selectedFile = ref(null);

const handleFileUpload = (event) => {
    selectedFile.value = event.target.files[0];
};

const fetchDataExp = async () => {
    const page = 'JoinUsPage';

    const sectionID = '665f492dde93dd002a393c41';

    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}/sections/${sectionID}`);
        const section = response.data;
        titleSectionExp.value = section.title;

        if (section && section.images && section.images.length > 0) {
            secondSectionImageExp.value = section.images[0].url;
        }
        secondSectionContentExp.value = section.content;
    } catch (error) {

        secondSectionImageExp.value = 'default_image_url'; // replace with your default image url
        secondSectionContentExp.value = 'Error loading content';
    }
};


const fetchDataGetStarted = async () => {
    const page = 'JoinUsPage';

    const sectionID = '665f4939de93dd002a393c47';

    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}/sections/${sectionID}`);
        const section = response.data;

        titleSectionGet.value = section.title;


        if (section && section.images && section.images.length > 0) {
            secondSectionImageGet.value = section.images[0].url;
        }
        secondSectionContentGet.value = section.content;
    } catch (error) {

        secondSectionImageGet.value = 'default_image_url'; // replace with your default image url
        secondSectionContentGet.value = 'Error loading content';
    }
};


const fetchDataFormHeader = async () => {
    const page = 'JoinUsPage';

    const sectionID = '665f4961de93dd002a393c4f';

    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}/sections/${sectionID}`);
        const section = response.data;

        fetchDataFormTitile.value = section.title;


        if (section && section.images && section.images.length > 0) {
            fetchDataFormImage.value = section.images[0].url;
        }
        fetchDataFormContent.value = section.content;
    } catch (error) {

        fetchDataFormImage.value = 'default_image_url';
        fetchDataFormContent.value = 'Error loading content';
    }
};


const fatchDataOfIntern = async () => {
    const page = 'JoinUsPage';

    const sectionID = '665f4973de93dd002a393c59';

    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}/sections/${sectionID}`);
        const section = response.data;

        fatchDataOfInternTitle.value = section.title;


        if (section && section.images && section.images.length > 0) {
            fatchDataOfInternImage.value = section.images[0].url;
            console.log(section.images[0].url);
        }
        fatchDataOfInternContent.value = section.content;
    } catch (error) {

        fatchDataOfInternImage.value = 'default_image_url'; // replace with your default image url
        fatchDataOfInternContent.value = 'Error loading content';
    }
};



const fatchDataOfpre = async () => {
    const page = 'JoinUsPage';

    const sectionID = '665f4980de93dd002a393c65';

    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}/sections/${sectionID}`);
        const section = response.data;

        titleSectionPre.value = section.title;


        if (section && section.images && section.images.length > 0) {
            secondSectionImagePre.value = section.images[0].url;
        }
        secondSectionContentPre.value = section.content;
    } catch (error) {

        secondSectionImagePre.value = 'default_image_url'; // replace with your default image url
        secondSectionContentPre.value = 'Error loading content';
    }
};

const submitForm = async () => {
    console.log('Form submission started');
    try {
        // Validate form data
        if (!formData.value.name || !formData.value.email || !formData.value.subject || !formData.value.message) {
            Swal.fire({
                icon: 'warning',
                title: 'Missing Information',
                text: 'Please fill in all required fields',
                confirmButtonColor: '#3085d6'
            });
            return;
        }

        // Show loading state
        Swal.fire({
            title: 'Submitting...',
            text: 'Please wait while we process your application',
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.value.name);
        formDataToSend.append('email', formData.value.email);
        formDataToSend.append('subject', formData.value.subject);
        formDataToSend.append('message', formData.value.message);
        
        if (selectedFile.value) {
            formDataToSend.append('resume', selectedFile.value);
        }

        const response = await axios.post(
            "http://localhost:3000/api/joinus",
            formDataToSend,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );

        if (response.data.success) {
            // Show success message
            await Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'Your application has been submitted successfully',
                confirmButtonColor: '#3085d6'
            });
            
            // Reset form
            formData.value = {
                name: '',
                email: '',
                subject: '',
                message: ''
            };
            selectedFile.value = null;
        }
    } catch (error) {
        console.error('Submission error:', error);
        
        // Show error message
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.response?.data?.message || 'Error submitting form. Please try again.',
            confirmButtonColor: '#3085d6'
        });
    }
};

onMounted(() => {

    fetchDataExp();
    fetchDataGetStarted();
    fetchDataFormHeader();
    fatchDataOfIntern();
    fatchDataOfpre();
});
</script>
