<template>
    <section class="testimonial-section">
        <div class="container">
            <div class="testimonial-slider">
                <button class="slider-btn prev" @click="prevSlide" :disabled="isAnimating">
                    <i class="fas fa-chevron-left"></i>
                </button>
                
                <div class="slider-wrapper" ref="sliderWrapper">
                    <div class="slider-container" 
                         :style="{ transform: `translateX(-${currentSlide * 100}%)` }"
                         @transitionend="handleTransitionEnd">
                        <div class="testimonial-slide" v-for="(section, index) in sections" :key="index">
                            <div class="testimonial-card">
                                <div class="image-container">
                                    <img :src="section.image" :alt="section.title" />
                                </div>
                                <div class="content-container">
                                    <h3>{{ section.title }}</h3>
                                    <p>{{ section.content }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <button class="slider-btn next" @click="nextSlide" :disabled="isAnimating">
                    <i class="fas fa-chevron-right"></i>
                </button>

                <div class="slider-dots">
                    <button 
                        v-for="(_, index) in sections" 
                        :key="index"
                        :class="['dot', { active: currentSlide === index }]"
                        @click="goToSlide(index)"
                        :disabled="isAnimating">
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import axios from 'axios';

const sections = ref([]);
const currentSlide = ref(0);
const isAnimating = ref(false);
const autoPlayInterval = ref(null);
const sliderWrapper = ref(null);

const nextSlide = () => {
    if (isAnimating.value) return;
    isAnimating.value = true;
    currentSlide.value = (currentSlide.value + 1) % sections.value.length;
};

const prevSlide = () => {
    if (isAnimating.value) return;
    isAnimating.value = true;
    currentSlide.value = currentSlide.value === 0 ? sections.value.length - 1 : currentSlide.value - 1;
};

const goToSlide = (index) => {
    if (isAnimating.value || currentSlide.value === index) return;
    isAnimating.value = true;
    currentSlide.value = index;
};

const handleTransitionEnd = () => {
    isAnimating.value = false;
};

const startAutoPlay = () => {
    autoPlayInterval.value = setInterval(nextSlide, 5000);
};

const stopAutoPlay = () => {
    if (autoPlayInterval.value) {
        clearInterval(autoPlayInterval.value);
    }
};

const fetchData = async () => {
    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/ClientPage`);
        const pageData = response.data;

        if (!pageData || !pageData.sections) {
            throw new Error('Invalid response data');
        }

        sections.value = pageData.sections.map(section => ({
            title: section.title || 'Default Title',
            content: section.content || 'Default content.',
            image: (section.images && section.images.length > 0) 
                ? section.images[0].url 
                : 'content/surveyor/images/default_image.jpg'
        }));

    } catch (error) {
        console.error('Error fetching data:', error);
        sections.value = [{
            title: 'Error',
            content: 'Error loading content.',
            image: 'content/surveyor/images/default_image.jpg'
        }];
    }
};

onMounted(() => {
    fetchData();
    startAutoPlay();
});

onBeforeUnmount(() => {
    stopAutoPlay();
});
</script>

<style scoped>
.testimonial-section {
    padding: 60px 0;
    background: #f8f9fa;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.testimonial-slider {
    position: relative;
    max-width: 1000px;
    margin: 0 auto;
}

.slider-wrapper {
    overflow: hidden;
    position: relative;
}

.slider-container {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.testimonial-slide {
    min-width: 100%;
    padding: 20px;
}

.testimonial-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    height: auto;
    display: flex;
    flex-direction: column;
}

.image-container {
    width: 100%;
    height: 300px;
    overflow: hidden;
    background: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform 0.3s ease;
}

.testimonial-card:hover .image-container img {
    transform: none;
}

.content-container {
    padding: 24px;
    flex: 1;
}

.content-container h3 {
    font-size: 1.25rem;
    color: #2c3e50;
    margin-bottom: 12px;
    font-weight: 600;
}

.content-container p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
    margin: 0;
}

.slider-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.9);
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
    transition: all 0.3s ease;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.slider-btn:hover:not(:disabled) {
    background: white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.slider-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.prev {
    left: -20px;
}

.next {
    right: -20px;
}

.slider-dots {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 8px;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #ddd;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 0;
}

.dot.active {
    background: #2c3e50;
    transform: scale(1.2);
}

.dot:hover:not(:disabled) {
    background: #bbb;
}

.dot:disabled {
    cursor: not-allowed;
}

/* Responsive Design */
@media (max-width: 768px) {
    .testimonial-section {
        padding: 40px 0;
    }

    .testimonial-slide {
        padding: 10px;
    }

    .image-container {
        height: 200px;
    }

    .content-container {
        padding: 16px;
    }

    .content-container h3 {
        font-size: 1.1rem;
    }

    .content-container p {
        font-size: 0.9rem;
    }

    .slider-btn {
        width: 35px;
        height: 35px;
    }

    .prev {
        left: -10px;
    }

    .next {
        right: -10px;
    }
}

@media (max-width: 480px) {
    .testimonial-section {
        padding: 30px 0;
    }

    .image-container {
        height: 180px;
    }

    .content-container {
        padding: 12px;
    }
}
</style>