<template>
    <div>
        <section class="pt-3 bg-very-light-gray sm-pt-50px position-relative">
            <div class="container">
                <div class="row row-cols-1 row-cols-lg-3 row-cols-md-2 justify-content-center mb-8">
                    <!-- start services box style -->
                    <div class="col mb-30px" v-for="(section, index) in sections.slice(1)" :key="index">
                        <div
                            class="services-box-style-01 box-shadow-extra-large last-paragraph-no-margin border-radius-6px overflow-hidden">
                            <div class="position-relative d-flex justify-content-center align-items-center">
                                <img :src="section.image" alt="" style="height: 200px;">
                            </div>
                            <div class="bg-white">
                                <div
                                    class="ps-65px pe-65px lg-ps-40px lg-pe-40px pt-30px pb-30px text-center progress-bar-style-01">
                                    <a class="d-inline-block fs-18 fw-700 text-dark-gray mb-5px">{{ section.title
                                        }}</a>
                                    <p
                                        v-html="section.content.replace('USP:', '<br><br><br><strong>USP:</strong>').split('•').join('<br>•')">
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end services box style -->

                </div>
            </div>
            <div class="shape-image-animation p-0 w-100 bottom-minus-40px xl-bottom-0px d-none d-md-block">
                <svg xmlns="http://www.w3.org/2000/svg" widht="3000" height="400" viewBox="0 180 2500 200"
                    fill="#ffffff">
                    <path class="st1" d="M 0 250 C 1200 400 1200 50 3000 250 L 3000 550 L 0 550 L 0 250">
                        <animate attributeName="d" dur="5s" values="M 0 250 C 1200 400 1200 50 3000 250 L 3000 550 L 0 550 L 0 250;
                            M 0 250 C 400 50 400 400 3000 250 L 3000 550 L 0 550 L 0 250;
                            M 0 250 C 1200 400 1200 50 3000 250 L 3000 550 L 0 550 L 0 250" repeatCount="indefinite" />
                    </path>
                </svg>
            </div>
        </section>
    </div>

</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

const sections = ref([]);
let main_title = ref('Loading...');
let titles = ref([]);
let serviceSections = ref([]);
let serviceImages = ref([]);

const sectionData = ref([]);
let mainTitle = ref('Loading...');
let sectionTitles = ref([]);
let sectionContents = ref([]);
let sectionImages = ref([]);


const fetchData = async () => {
    const page = 'ServicesPage';

    try {
        const idsResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/getNonUSPSectionIds/ServicesPage`);
        const sectionIDs = idsResponse.data;

        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}`);
        const pageData = response.data;
        const filteredSections = pageData.sections.filter(section => sectionIDs.includes(section._id));

        sections.value = filteredSections.map(section => ({
            title: section.title || 'Default Title',
            content: section.content || 'Default content.',
            image: (section.images && section.images.length > 0) ? section.images[0].url : ''
        }));

        titles.value = filteredSections.map(section => section.title);
        serviceSections.value = filteredSections.map(section => section.content);
        serviceImages.value = filteredSections.map(section => section.images[0].url);

        main_title.value = filteredSections[0].title;

    } catch (error) {
        console.error('Error fetching data:', error);
        sections.value = [{
            title: 'Error',
            content: 'Error loading content.',
            image: 'content/surveyor/images/default_image.jpg'
        }];
    }
};

const fetchUSPData = async () => {
    const pageName = 'ServicesPage';

    try {
        const idResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/getNonUSPSectionIds/${pageName}`);
        const nonUSPSectionIDs = idResponse.data;
        console.log(nonUSPSectionIDs);
        const pageResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${pageName}`);
        const pageData = pageResponse.data;
        const nonUSPSections = pageData.sections.filter(section => nonUSPSectionIDs.includes(section._id));

        sectionData.value = nonUSPSections.map(section => ({
            title: section.title || 'Default Title',
            content: section.content || 'Default content.',
            image: (section.images && section.images.length > 0) ? section.images[0].url : 'content/surveyor/images/default_image.jpg'
        }));

        console.log(sectionData.value);
        sectionTitles.value = nonUSPSections.map(section => section.title);
        sectionContents.value = nonUSPSections.map(section => section.content);
        sectionImages.value = nonUSPSections.map(section => section.images[0].url);

        mainTitle.value = nonUSPSections[0].title;

    } catch (error) {
        console.error('Error fetching data:', error);
        sectionData.value = [{
            title: 'Error',
            content: 'Error loading content.',
            image: 'content/surveyor/images/default_image.jpg'
        }];
    }
};
onMounted(() => {
    fetchData();
    fetchUSPData();
});
</script>



<style scoped></style>