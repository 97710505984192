<template>
    <div>
        <Header />
        <broadcamb />
        <services />
    </div>

</template>

<script>


import Header from '../components/layouts/Header.vue'
import broadcamb from '../components/layouts/broadCambPart.vue'
import services from '../components/testto/ServicesComponent.vue'
export default {
    name: 'TestomonialPage',
    components: {

        Header,
        broadcamb,
        services

    },
}
</script>

<style scoped></style>